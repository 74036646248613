import React from "react";
import { shallow } from "zustand/shallow";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import SearchBar from "material-ui-search-bar";

import Layout from "../Layout/Layout";
import NominaTable from "./NominaTable";

import { getCompanyEmployees } from "../../services/CompaniesService";

import { useEmpresaStore } from "../../store/empresaStore";

import { NominaRowsType } from "../../types";

const Nomina = () => {
  const [searched, setSearched] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [payroll, setPayroll] = React.useState<NominaRowsType[]>([]);
  const [orignialPayroll, setOriginalPayroll] = React.useState<
    NominaRowsType[]
  >([]);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);

  const { getAccessTokenSilently } = useAuth0();

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const requestSearch = (searchedVal: string) => {
    const searchPayroll = [...orignialPayroll];
    if (searchedVal.length > 2) {
      const filteredRows = searchPayroll.reduce(
        (result: NominaRowsType[], row) => {
          const name = row.name?.toString().toLowerCase();
          const lastName = row.lastName?.toString().toLowerCase();
          const idEmployee =
            row.fileNumber.toString().toLowerCase();
          const document = row.document?.toString().toLowerCase();

          if (
            name?.includes(searchedVal.toLowerCase()) ||
            lastName?.includes(searchedVal.toLowerCase()) ||
            idEmployee?.includes(searchedVal.toLowerCase()) ||
            document?.includes(searchedVal.toLowerCase())
          ) {
            result.push(row);
          }

          return result;
        },
        []
      );
      setPayroll(filteredRows);
    } else {
      setPayroll(orignialPayroll);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  React.useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getCompanyEmployees(token, empresaId, page + 1)
        .then((result) => {
          setPayroll(result.data.paginationList);
          setOriginalPayroll(result.data.paginationList);

          // Some API clients return undefined while loading
          // Following lines are here to prevent `rowCountState` from being undefined during the loading
          setRowCountState((prevRowCountState) =>
            result.data.quantity !== undefined
              ? result.data.quantity
              : prevRowCountState
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setPayroll([]);
          setIsLoading(false);
        });
    };

    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId, page]);

  return (
    <Layout icon="PeopleAltOutlinedIcon" title="Nomina">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container sx={{ py: "24px", placeItems: "center" }}>
          <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
            <Box sx={{ marginRight: 4, width: "459px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Buscar empleado"
                style={{ boxShadow: "none" }}
              />
            </Box>
          </Grid>
          {/* <Grid item xs>
            <Grid container display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
        <NominaTable
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isLoading={isLoading}
          page={page}
          payroll={payroll}
          rowCountState={rowCountState}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
        />
      </Box>
    </Layout>
  );
};

export default Nomina;
