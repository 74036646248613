import http from "../api";
import {
  CompanyNoveltiesType,
  CompanyPeriodsType,
  CompanyType,
  CompanyUsersRowsType,
  EmpresaType,
  NominaRespType,
  TenantType,
  TipoPeriodoRowsType,
} from "../types";

const getAllCompanies = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get("/api/v1/companies");
};

const getUsersCompanies = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<EmpresaType[]>("/api/v1/users/companies");
};

const getCompany = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/companies/${companyId}`);
};

const getCompanyUsers = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyUsersRowsType[]>(
    `/api/v1/companies/${companyId}/users`
  );
};

const getCompaniesByUser = (token: string, userId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyType[]>(`/api/v1/users/${userId}/companies`);
};

const getCompanyPeriods = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyPeriodsType[]>(
    `/api/v1/companies/${companyId}/periods`
  );
};
const getCompanyPeriodById = (token: string, companyId: string, periodId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyPeriodsType>(
    `/api/v1/companies/${companyId}/periods/${periodId}`
  );
};

const getCompanyNovelties = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyNoveltiesType[]>(
    `/api/v1/companies/${companyId}/novelties`
  );
};

const getHistoricCompanyNovelties = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyNoveltiesType[]>(
    `/api/v1/companies/${companyId}/novelties/historic`
  );
};

const getHistoricCompanyNoveltiesByPeriodId = (
  token: string,
  companyId: string,
  periodId?: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return periodId
    ? http.get<CompanyNoveltiesType[]>(
        `/api/v1/companies/${companyId}/novelties/historic?period_id=${periodId}`
      )
    : http.get<CompanyNoveltiesType[]>(
        `/api/v1/companies/${companyId}/novelties/historic`
      );
};

const getCompanyNoveltiesByPeriodId = (
  token: string,
  companyId: string,
  periodId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyNoveltiesType[]>(
    `/api/v1/companies/${companyId}/novelties?period_id=${periodId}`
  );
};

const getCompanyNoveltyById = (
  token: string,
  companyId: string,
  noveltyId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<CompanyNoveltiesType>(
    `/api/v1/companies/${companyId}/novelties/${noveltyId}`
  );
};

const getCompanyPeriodsTypes = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<TipoPeriodoRowsType[]>(
    `/api/v1/companies/${companyId}/periodType`
  );
};

const getCompanyEmployees = (
  token: string,
  companyId: string,
  page: number | string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<NominaRespType>(
    `/api/v1/companies/${companyId}/employees?page=${page}`
  );
};

const getPeriodType = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get("/api/v1/parameters/periodType");
};

const getCompanyNoveltiesTypes = (token: string, company_id: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<any[]>(`/api/v1/companies/${company_id}/noveltyType`);
};

const addCompany = (token: string, companyData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post("/api/v1/companies", companyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addEmployee = (token: string, employeeData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const employeeObject = {
    user_id: employeeData.user_id,
    company_id: employeeData.company_id,
    role: 0,
  };

  return http.post("/api/v1/users/invite", employeeObject);
};

const editCompany = (token: string, companyData: any, empresaId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(`/api/v1/companies/${empresaId}`, companyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addCompanyNovelty = (
  token: string,
  company_id: string,
  noveltyData: any
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(`/api/v1/companies/${company_id}/novelties`, noveltyData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addCompanyNoveltyType = (token: string, data: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(`/api/v1/parameters/noveltyType/company`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const editCompanyNoveltyType = (
  token: string,
  companyId: string,
  data: any
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(
    `/api/v1/parameters/noveltyType/company/${companyId}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const deleteCompanyNoveltyType = (token: string, noveltyId: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.delete(`/api/v1/parameters/noveltyType/company/${noveltyId}`);
};

const deleteUserFromCompany = (token: string, userId: any, userData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.delete(`/api/v1/companies/users/${userId}`, userData);
};

const addCompanyPeriod = (token: string, periodData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(`/api/v1/parameters/periodType/company`, periodData);
};

const getCompanyNoveltyTypeById = (
  token: string,
  company_id: string,
  noveltyTypeId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(
    `/api/v1/companies/${company_id}/noveltyType/${noveltyTypeId}`
  );
};

const getCompanyTenants = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<TenantType[]>("/api/v1/companies/tenants");
};

const cancelNoveltyProcessing = (
  token: string,
  company_id: string,
  noveltyId: string,
  noveltyData: any
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(
    `/api/v1/companies/${company_id}/novelties/${noveltyId}/cancel`,
    noveltyData
  );
};

const editCompanyNovelty = (
  token: string,
  company_id: string,
  noveltyTypeId: string,
  noveltyData: any
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(
    `/api/v1/companies/${company_id}/novelties/${noveltyTypeId}`,
    noveltyData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const processNovelty = (
  token: string,
  companyId: string,
  noveltyData: { id: string }
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(`/api/v1/companies/${companyId}/novelties/run`, noveltyData);
};

const getNoveltyLogs = (
  token: string,
  companyId: string,
  noveltyId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/companies/${companyId}/novelties/${noveltyId}/logs`);
};

const unblockNovelty = (
  token: string,
  companyId: string,
  noveltyId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(
    `/api/v1/companies/${companyId}/novelties/${noveltyId}/unblock`
  );
};

const inviteCompanyUser = (token: string, inviteData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post(`/api/v1/companies/users`, inviteData);
};

export {
  addCompany,
  addCompanyNovelty,
  addCompanyNoveltyType,
  addCompanyPeriod,
  addEmployee,
  cancelNoveltyProcessing,
  deleteCompanyNoveltyType,
  deleteUserFromCompany,
  editCompany,
  editCompanyNovelty,
  editCompanyNoveltyType,
  getAllCompanies,
  getCompaniesByUser,
  getCompany,
  getCompanyEmployees,
  getCompanyNovelties,
  getCompanyNoveltiesByPeriodId,
  getCompanyNoveltiesTypes,
  getCompanyNoveltyById,
  getCompanyNoveltyTypeById,
  getCompanyPeriods,
  getCompanyPeriodById,
  getCompanyPeriodsTypes,
  getCompanyTenants,
  getCompanyUsers,
  getHistoricCompanyNovelties,
  getHistoricCompanyNoveltiesByPeriodId,
  getNoveltyLogs,
  getPeriodType,
  getUsersCompanies,
  inviteCompanyUser,
  processNovelty,
  unblockNovelty,
};
