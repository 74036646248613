import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  gridClasses,
} from "@mui/x-data-grid";
// import { shallow } from "zustand/shallow";
// import { useAuth0 } from "@auth0/auth0-react";

import { alpha, styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";

// import { getCompanyEmployees } from "../../services/CompaniesService";
// import { useEmpresaStore } from "../../store/empresaStore";

import { NominaRowsType } from "../../types";

const ODD_OPACITY = 0.2;

const getLegajo = (params: GridRenderCellParams) => {
  return params.row.fileNumber;
};

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#F2F2F2",
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nombre",
    headerClassName: "theme--header",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Apellido",
    headerClassName: "theme--header",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Legajo",
    headerClassName: "theme--header",
    flex: 1,
    renderCell: getLegajo,
  },
  {
    field: "cuil",
    headerName: "Cuil",
    headerClassName: "theme--header",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Estado",
    headerClassName: "theme--header",
    flex: 1,
  },
];

type NominaTableTable = {
  isLoading: boolean;
  page: number;
  payroll: NominaRowsType[];
  rowCountState: number;
  rowsPerPage: number;

  handleChangePage: (_: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;

  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const NominaTable: React.FC<NominaTableTable> = ({
  isLoading,
  page,
  payroll,
  rowCountState,
  rowsPerPage,

  handleChangePage,
  handleChangeRowsPerPage,

  setPage,
}) => {
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // const [payroll, setPayroll] = React.useState<NominaRowsType[]>([]);
  // const [rowCountState, setRowCountState] = React.useState(0);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);

  /* const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  }; */

  // const { getAccessTokenSilently } = useAuth0();

  // const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  /*   React.useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getCompanyEmployees(token, empresaId, page + 1)
        .then((result) => {
          console.log(result.data);
          setPayroll(result.data.paginationList);

          // Some API clients return undefined while loading
          // Following lines are here to prevent `rowCountState` from being undefined during the loading
          setRowCountState((prevRowCountState) =>
            result.data.quantity !== undefined
              ? result.data.quantity
              : prevRowCountState
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setPayroll([]);
          setIsLoading(false);
        });
    };

    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId, page]); */

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
        "& .MuiTablePagination-toolbar": {
          paddingLeft: "18px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          height: "74%",
          borderRadius: "6px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box display="flex" justifyContent="flex-start">
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              count={rowCountState}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Mostrar Registros"
            />
          </Box>
          <StripedDataGrid
            rows={payroll}
            rowCount={rowCountState}
            loading={isLoading}
            rowsPerPageOptions={[rowsPerPage]}
            pagination
            page={page}
            pageSize={rowsPerPage}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            getRowId={(row) => row.idPerson}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              columnMenuLabel: "Menu",
              columnMenuShowColumns: "Mostrar Columna",
              columnMenuFilter: "Filtrar",
              columnMenuHideColumn: "Ocultar",
              columnMenuUnsort: "Deshacer",
              columnHeaderSortIconLabel: "Ordenar",
              columnMenuSortAsc: "Ordenar por ASC",
              columnMenuSortDesc: "Ordenar por DESC",
              noResultsOverlayLabel: "Sin Resultados",
              noRowsLabel: "Sin Resultados",
            }}
            sx={{
              backgroundColor: "white",
              border: "1px solid white",
              borderRadius: "6px",
              paddingLeft: "18px",
              paddingRight: "18px",
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            hideFooterPagination
            hideFooter
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NominaTable;
