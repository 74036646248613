import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Layout from "../../Layout/Layout";
import { CompanyPeriodsType } from "../../../types";
import { getCompanyPeriodById } from "../../../services/CompaniesService";
import { useEmpresaStore } from "../../../store/empresaStore";
import { shallow } from "zustand/shallow";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { getPeriodDuration } from "../../../utils/getPeriodDuration";


const types = {
  "1": {
    value: "1",
    label: "Quincenal",
  },
  "0": {
    value: "2",
    label: "Mensual",
  },
}

const statuses = [
  {
    value: "1",
    label: "Activo",
  },
  {
    value: "2",
    label: "Inactivo",
  },
];

type PeriodoDetailsType = {
  params: any;
};
const PeriodoDetails: React.FC<PeriodoDetailsType> = ({ params }) => {
  const [type, setType] = useState<string>("1");
  const [status, setStatus] = useState<string>("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const [period, setPeriod] = useState<CompanyPeriodsType>();
  const periodId = params.periodoId;

  const { getAccessTokenSilently } = useAuth0();

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const navigate = useNavigate();
  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };
  const getPeriod = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyPeriodById(token, empresaId, periodId)
      .then((result) => {
        console.log(result, 'here')
        setPeriod(result.data)
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchErrorMessage(e.response.data.error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  useEffect(() => {
    getPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Periodo`;
  }, []);

  return (
    <Layout icon="CalendarTodayIcon" title="Periodo" breadcrumb>
      <Grid container borderRadius="6px">
        <Grid bgcolor="white" item xs={6} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
              >
                <Grid container maxWidth="md" justifyContent="space-between">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="fecha-inicio-input"
                      >
                        Fecha de inicio
                      </InputLabel>
                    </Box>
                    <TextField
                      id="fecha-inicio-input"
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      value={period && dayjs(period?.start_date).format("DD/MM/YYYY")}
                      variant="filled"
                      placeholder="Fecha de inicio"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="fecha-cierre-input"
                      >
                        Fecha limite
                      </InputLabel>
                    </Box>
                    <TextField
                      id="fecha-cierre-input"
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      value={period && dayjs(period?.day_limit).format("DD/MM/YYYY")}
                      variant="filled"
                      placeholder="Fecha de cierre"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  maxWidth="md"
                  justifyContent="space-between"
                  paddingBottom="26px"
                >
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="tipo-input">
                        Tipo
                      </InputLabel>
                    </Box>
                    {/* <TextField
                      id="tipo-input"
                      select
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="type"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      // value={formik.values.email}
                      variant="filled"
                      value={type}
                      onChange={handleChangeType}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {types.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <TextField
                      id="tipo-input"
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="tipo"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      value={period && getPeriodDuration(period.duration)}
                      variant="filled"
                      placeholder="Fecha de inicio"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="status-input"
                      >
                        Estado
                      </InputLabel>
                    </Box>
                    <TextField
                      id="fecha-inicio-input"
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      value={period && period.period_state === 1 ? "Inactivo" : "Activo"}
                      variant="filled"
                      placeholder="Fecha de inicio"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  maxWidth="sm"
                  paddingBottom="32px"
                  paddingTop="100px"
                >
                  <Button
                    sx={{ marginLeft: "18px" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate(-1)}
                  >
                    Volver
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default PeriodoDetails;
